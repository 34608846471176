import React from 'react'

export default function LogoVector() {
    return (
        <svg className="logo" width="100" height="37" viewBox="0 0 100 37" fill="black" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.579 0.300304C15.7124 -0.243724 17.0252 0.582308 17.0252 1.8395V3.23568C17.0252 3.33668 16.9716 3.43009 16.8844 3.48105L5.63598 10.0555C4.34211 10.8117 4.34371 12.6821 5.63886 13.4362L16.8561 19.9667C16.9608 20.0277 17.0252 20.1397 17.0252 20.2609V21.2872C17.0252 22.7943 15.363 23.7091 14.0898 22.9027L0.979683 15.3462C0.373522 14.9968 0 14.3504 0 13.6507V9.93635C0 9.24063 0.369377 8.59722 0.970179 8.24642L14.579 0.300304Z" fill="currentColor"/>
            <path d="M10.5674 11.8461L17.172 7.94822V15.744L10.5674 11.8461Z" fill="currentColor"/>
            <path d="M14.579 0.300304C15.7124 -0.243724 17.0252 0.582308 17.0252 1.8395V3.23568C17.0252 3.33668 16.9716 3.43009 16.8844 3.48105L5.63598 10.0555C4.34211 10.8117 4.34371 12.6821 5.63886 13.4362L16.8561 19.9667C16.9608 20.0277 17.0252 20.1397 17.0252 20.2609V21.2872C17.0252 22.7943 15.363 23.7091 14.0898 22.9027L0.979683 15.3462C0.373522 14.9968 0 14.3504 0 13.6507V9.93635C0 9.24063 0.369377 8.59722 0.970179 8.24642L14.579 0.300304Z" fill="currentColor"/>
            <path d="M10.5674 11.8461L17.172 7.94822V15.744L10.5674 11.8461Z" fill="currentColor"/>
            <path d="M21.8196 36.699C20.6862 37.243 19.3735 36.417 19.3735 35.1598V33.7636C19.3735 33.6626 19.4271 33.5692 19.5143 33.5182L30.7627 26.9438C32.0566 26.1876 32.055 24.3172 30.7598 23.5631L19.5426 17.0326C19.4379 16.9716 19.3735 16.8596 19.3735 16.7384V15.7121C19.3735 14.205 21.0357 13.2902 22.3089 14.0966L35.419 21.6531C36.0252 22.0025 36.3987 22.6489 36.3987 23.3486V27.0629C36.3987 27.7587 36.0293 28.4021 35.4285 28.7529L21.8196 36.699Z" fill="currentColor"/>
            <path d="M25.8313 25.1532L19.2267 29.0511V21.2553L25.8313 25.1532Z" fill="currentColor"/>
            <path d="M21.8196 36.699C20.6862 37.243 19.3735 36.417 19.3735 35.1598V33.7636C19.3735 33.6626 19.4271 33.5692 19.5143 33.5182L30.7627 26.9438C32.0566 26.1876 32.055 24.3172 30.7598 23.5631L19.5426 17.0326C19.4379 16.9716 19.3735 16.8596 19.3735 16.7384V15.7121C19.3735 14.205 21.0357 13.2902 22.3089 14.0966L35.419 21.6531C36.0252 22.0025 36.3987 22.6489 36.3987 23.3486V27.0629C36.3987 27.7587 36.0293 28.4021 35.4285 28.7529L21.8196 36.699Z" fill="currentColor"/>
            <path d="M25.8313 25.1532L19.2267 29.0511V21.2553L25.8313 25.1532Z" fill="currentColor"/>
            <path d="M39.5188 32.2529V26.5465H42.7594C42.9285 26.5465 43.0812 26.5888 43.2174 26.6733C43.3583 26.7578 43.4686 26.8682 43.5485 27.0044C43.633 27.1406 43.6753 27.2933 43.6753 27.4623V29.7167C43.6753 29.8858 43.633 30.0384 43.5485 30.1746C43.4686 30.3108 43.3583 30.4212 43.2174 30.5057C43.0812 30.5903 42.9285 30.6325 42.7594 30.6325H40.2796V32.2529H39.5188ZM40.4558 29.8717H42.7383C42.7853 29.8717 42.8252 29.8553 42.8581 29.8224C42.8957 29.7848 42.9144 29.7425 42.9144 29.6956V27.4835C42.9144 27.4365 42.8957 27.3966 42.8581 27.3637C42.8252 27.3261 42.7853 27.3073 42.7383 27.3073H40.4558C40.4088 27.3073 40.3665 27.3261 40.329 27.3637C40.2961 27.3966 40.2796 27.4365 40.2796 27.4835V29.6956C40.2796 29.7425 40.2961 29.7848 40.329 29.8224C40.3665 29.8553 40.4088 29.8717 40.4558 29.8717Z" fill="currentColor"/>
            <path d="M48.6142 30.6325C48.4451 30.6325 48.2925 30.5903 48.1563 30.5057C48.0201 30.4212 47.9097 30.3108 47.8252 30.1746C47.7406 30.0384 47.6984 29.8858 47.6984 29.7167V27.4623C47.6984 27.2933 47.7406 27.1406 47.8252 27.0044C47.9097 26.8682 48.0201 26.7578 48.1563 26.6733C48.2925 26.5888 48.4451 26.5465 48.6142 26.5465H50.939C51.1081 26.5465 51.2607 26.5888 51.3969 26.6733C51.5378 26.7531 51.6482 26.8635 51.7281 27.0044C51.8126 27.1453 51.8549 27.298 51.8549 27.4623V29.7167C51.8549 29.8858 51.8126 30.0384 51.7281 30.1746C51.6482 30.3108 51.5378 30.4212 51.3969 30.5057C51.2607 30.5903 51.1081 30.6325 50.939 30.6325H48.6142ZM48.6353 29.8717H50.9179C50.9649 29.8717 51.0048 29.8553 51.0377 29.8224C51.0705 29.7848 51.087 29.7425 51.087 29.6956V27.4835C51.087 27.4365 51.0705 27.3966 51.0377 27.3637C51.0048 27.3261 50.9649 27.3073 50.9179 27.3073H48.6353C48.5884 27.3073 48.5461 27.3261 48.5085 27.3637C48.4757 27.3966 48.4592 27.4365 48.4592 27.4835V29.6956C48.4592 29.7425 48.4757 29.7848 48.5085 29.8224C48.5461 29.8553 48.5884 29.8717 48.6353 29.8717Z" fill="currentColor"/>
            <path d="M55.9956 30.6325V27.4623C55.9956 27.2933 56.0378 27.1406 56.1224 27.0044C56.2069 26.8682 56.3173 26.7578 56.4535 26.6733C56.5944 26.5888 56.747 26.5465 56.9114 26.5465H59.194V27.3073H56.9325C56.8856 27.3073 56.8433 27.3261 56.8057 27.3637C56.7728 27.3966 56.7564 27.4365 56.7564 27.4835V30.6325H55.9956Z" fill="currentColor"/>
            <path d="M64.0803 30.6325C63.9112 30.6325 63.7562 30.5903 63.6153 30.5057C63.4791 30.4212 63.3688 30.3108 63.2842 30.1746C63.2044 30.0384 63.1645 29.8858 63.1645 29.7167V25.2573H63.9253V26.5465H65.602V27.3073H63.9253V29.6956C63.9253 29.7425 63.9418 29.7848 63.9746 29.8224C64.0122 29.8553 64.0545 29.8717 64.1014 29.8717H65.602V30.6325H64.0803Z" fill="currentColor"/>
            <path d="M69.6796 30.6325V26.1238C69.6796 25.9547 69.7196 25.8021 69.7994 25.6659C69.8839 25.525 69.9943 25.4146 70.1305 25.3348C70.2714 25.2502 70.4264 25.208 70.5955 25.208H72.1172V25.9688H70.6166C70.5696 25.9688 70.5274 25.9876 70.4898 26.0252C70.4569 26.058 70.4405 26.098 70.4405 26.1449V26.5465H72.1172V27.3073H70.4405V30.6325H69.6796Z" fill="currentColor"/>
            <path d="M77.0828 30.6325C76.9137 30.6325 76.7611 30.5903 76.6249 30.5057C76.4887 30.4212 76.3783 30.3108 76.2938 30.1746C76.2092 30.0384 76.1669 29.8858 76.1669 29.7167V27.4623C76.1669 27.2933 76.2092 27.1406 76.2938 27.0044C76.3783 26.8682 76.4887 26.7578 76.6249 26.6733C76.7611 26.5888 76.9137 26.5465 77.0828 26.5465H79.4076C79.5767 26.5465 79.7293 26.5888 79.8655 26.6733C80.0064 26.7531 80.1168 26.8635 80.1966 27.0044C80.2812 27.1453 80.3234 27.298 80.3234 27.4623V29.7167C80.3234 29.8858 80.2812 30.0384 80.1966 30.1746C80.1168 30.3108 80.0064 30.4212 79.8655 30.5057C79.7293 30.5903 79.5767 30.6325 79.4076 30.6325H77.0828ZM77.1039 29.8717H79.3865C79.4334 29.8717 79.4734 29.8553 79.5062 29.8224C79.5391 29.7848 79.5555 29.7425 79.5555 29.6956V27.4835C79.5555 27.4365 79.5391 27.3966 79.5062 27.3637C79.4734 27.3261 79.4334 27.3073 79.3865 27.3073H77.1039C77.057 27.3073 77.0147 27.3261 76.9771 27.3637C76.9442 27.3966 76.9278 27.4365 76.9278 27.4835V29.6956C76.9278 29.7425 76.9442 29.7848 76.9771 29.8224C77.0147 29.8553 77.057 29.8717 77.1039 29.8717Z" fill="currentColor"/>
            <path d="M85.3318 30.6325C85.1627 30.6325 85.0101 30.5903 84.8739 30.5057C84.7377 30.4212 84.6273 30.3108 84.5428 30.1746C84.4582 30.0384 84.416 29.8858 84.416 29.7167V25.208H85.1768V29.6956C85.1768 29.7425 85.1933 29.7848 85.2261 29.8224C85.2637 29.8553 85.306 29.8717 85.353 29.8717H86.0927V30.6325H85.3318Z" fill="currentColor"/>
            <path d="M90.2363 30.6325V26.5465H90.9971V30.6325H90.2363ZM90.2363 25.9688V25.208H90.9971V25.9688H90.2363Z" fill="currentColor"/>
            <path d="M96.188 30.6325C96.0189 30.6325 95.8662 30.5903 95.73 30.5057C95.5938 30.4212 95.4835 30.3108 95.3989 30.1746C95.3144 30.0384 95.2721 29.8858 95.2721 29.7167V27.4623C95.2721 27.2933 95.3144 27.1406 95.3989 27.0044C95.4835 26.8682 95.5938 26.7578 95.73 26.6733C95.8662 26.5888 96.0189 26.5465 96.188 26.5465H98.5128C98.6819 26.5465 98.8345 26.5888 98.9707 26.6733C99.1116 26.7531 99.222 26.8635 99.3018 27.0044C99.3864 27.1453 99.4286 27.298 99.4286 27.4623V29.7167C99.4286 29.8858 99.3864 30.0384 99.3018 30.1746C99.222 30.3108 99.1116 30.4212 98.9707 30.5057C98.8345 30.5903 98.6819 30.6325 98.5128 30.6325H96.188ZM96.2091 29.8717H98.4916C98.5386 29.8717 98.5785 29.8553 98.6114 29.8224C98.6443 29.7848 98.6607 29.7425 98.6607 29.6956V27.4835C98.6607 27.4365 98.6443 27.3966 98.6114 27.3637C98.5785 27.3261 98.5386 27.3073 98.4916 27.3073H96.2091C96.1621 27.3073 96.1199 27.3261 96.0823 27.3637C96.0494 27.3966 96.033 27.4365 96.033 27.4835V29.6956C96.033 29.7425 96.0494 29.7848 96.0823 29.8224C96.1199 29.8553 96.1621 29.8717 96.2091 29.8717Z" fill="currentColor"/>
            <path d="M39.5767 19.2824C39.201 19.2824 38.8566 19.1937 38.5435 19.0163C38.2408 18.8284 37.9955 18.5779 37.8077 18.2648C37.6302 17.9517 37.5415 17.6125 37.5415 17.2472V12.2375C37.5415 11.8618 37.6302 11.5226 37.8077 11.2199C37.9955 10.9172 38.2408 10.672 38.5435 10.4841C38.8566 10.2962 39.201 10.2023 39.5767 10.2023H45.0874V7.22779H46.7782V19.2824H39.5767ZM39.6393 17.5916H44.7117C44.816 17.5916 44.9048 17.5551 44.9778 17.482C45.0509 17.3986 45.0874 17.3046 45.0874 17.2003V12.2845C45.0874 12.1801 45.0509 12.0914 44.9778 12.0183C44.9048 11.9348 44.816 11.8931 44.7117 11.8931H39.6393C39.535 11.8931 39.441 11.9348 39.3575 12.0183C39.2845 12.0914 39.248 12.1801 39.248 12.2845V17.2003C39.248 17.3046 39.2845 17.3986 39.3575 17.482C39.441 17.5551 39.535 17.5916 39.6393 17.5916Z" fill="currentColor"/>
            <path d="M50.6057 19.2824C50.2299 19.2824 49.8907 19.1885 49.5881 19.0006C49.2854 18.8128 49.0401 18.5675 48.8523 18.2648C48.6644 17.9621 48.5705 17.6229 48.5705 17.2472V12.2375C48.5705 11.8618 48.6644 11.5226 48.8523 11.2199C49.0401 10.9172 49.2854 10.672 49.5881 10.4841C49.8907 10.2962 50.2299 10.2023 50.6057 10.2023H55.7719C56.1477 10.2023 56.4869 10.2962 56.7895 10.4841C57.1026 10.6615 57.3479 10.9068 57.5253 11.2199C57.7132 11.533 57.8071 11.8722 57.8071 12.2375V15.5878H50.2612V17.2003C50.2612 17.3046 50.2978 17.3986 50.3708 17.482C50.4543 17.5551 50.5483 17.5916 50.6526 17.5916H57.8071V19.2824H50.6057ZM50.2612 14.0066H56.1007V12.2845C56.1007 12.1801 56.0642 12.0914 55.9911 12.0183C55.918 11.9348 55.8293 11.8931 55.725 11.8931H50.6526C50.5483 11.8931 50.4543 11.9348 50.3708 12.0183C50.2978 12.0914 50.2612 12.1801 50.2612 12.2845V14.0066Z" fill="currentColor"/>
            <path d="M63.8999 19.2824L58.8902 10.2023H60.8471L64.7453 17.3725L68.6434 10.2023H70.6004L65.5906 19.2824H63.8999Z" fill="currentColor"/>
            <path d="M68.4718 22.8831V21.1767H72.3074C72.4118 21.1767 72.5005 21.1402 72.5735 21.0671C72.657 20.9941 72.6988 20.9054 72.6988 20.801V10.2023H74.3896V20.8479C74.3896 21.2237 74.2956 21.5629 74.1078 21.8655C73.9303 22.1787 73.6851 22.4239 73.372 22.6013C73.0693 22.7892 72.7301 22.8831 72.3544 22.8831H68.4718ZM72.6988 8.91857V7.22779H74.3896V8.91857H72.6988Z" fill="currentColor"/>
            <path d="M78.2496 19.2824C77.8739 19.2824 77.5347 19.1885 77.232 19.0006C76.9293 18.8128 76.684 18.5675 76.4962 18.2648C76.3083 17.9621 76.2144 17.6229 76.2144 17.2472V12.2375C76.2144 11.8618 76.3083 11.5226 76.4962 11.2199C76.684 10.9172 76.9293 10.672 77.232 10.4841C77.5347 10.2962 77.8739 10.2023 78.2496 10.2023H83.4158C83.7916 10.2023 84.1308 10.2962 84.4334 10.4841C84.7466 10.6615 84.9918 10.9068 85.1692 11.2199C85.3571 11.533 85.451 11.8722 85.451 12.2375V15.5878H77.9052V17.2003C77.9052 17.3046 77.9417 17.3986 78.0148 17.482C78.0982 17.5551 78.1922 17.5916 78.2966 17.5916H85.451V19.2824H78.2496ZM77.9052 14.0066H83.7446V12.2845C83.7446 12.1801 83.7081 12.0914 83.635 12.0183C83.562 11.9348 83.4733 11.8931 83.3689 11.8931H78.2966C78.1922 11.8931 78.0982 11.9348 78.0148 12.0183C77.9417 12.0914 77.9052 12.1801 77.9052 12.2845V14.0066Z" fill="currentColor"/>
            <path d="M87.5549 19.2824V9.26299C87.5549 8.88726 87.6436 8.54806 87.821 8.24539C88.0089 7.93229 88.2541 7.68702 88.5568 7.50959C88.8699 7.32173 89.2143 7.22779 89.5901 7.22779H92.9716V8.91857H89.637C89.5326 8.91857 89.4387 8.96032 89.3552 9.04381C89.2822 9.11687 89.2456 9.20559 89.2456 9.30996V10.2023H92.9716V11.8931H89.2456V19.2824H87.5549Z" fill="currentColor"/>
            <path d="M94.5832 19.2824V9.26299C94.5832 8.88726 94.672 8.54806 94.8494 8.24539C95.0373 7.93229 95.2825 7.68702 95.5852 7.50959C95.8983 7.32173 96.2427 7.22779 96.6184 7.22779H100V8.91857H96.6654C96.561 8.91857 96.4671 8.96032 96.3836 9.04381C96.3106 9.11687 96.274 9.20559 96.274 9.30996V10.2023H100V11.8931H96.274V19.2824H94.5832Z" fill="currentColor"/>
        </svg>
    )
}
