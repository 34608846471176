import React from 'react'

export default function Footer() {
    return (
        <footer className='footer'>
            <div className="container">
                <span className='footer__copyright'>© Jeffrey Lustica - 2022</span>
            </div>
        </footer>
    )
}
