import images from '../images/index'

const data = [
    {id: 1, source: `${images.picSome}`, secondaryTitle: "React App", mainTitle: "Pic Some", desc: "ReactJS-based web application resembling an e-commerce platform. Images/Photos can be added to your cart and ordered on the checkout page. I've utilized npm packages, React Context, and custom hooks.", tags: ["React Js", "React Context", "Custom Hooks", "PropTypes", "SPA", "API", "Git"], link: "https://jeffreylustica.github.io/pic-some/"},
    {id: 2, source: `${images.portfolio}`, secondaryTitle: "React App", mainTitle: "Portfolio", desc: "My online resume. Here I demonstrate my abilities as a front-end developer and my projects. I've utilized responsive web design, React hooks, custom hooks, Saas, and some npm packages.", tags: ["React Js", "Saas", "Custom Hooks", "npm", "Git", "Responsive Design"], link: "https://devjeff-portfolio.pages.dev/"},
    {id: 3, source: `${images.quizzicalApp}`, secondaryTitle: "React App", mainTitle: "Quizzical App", desc: "A trivia game app created with the fundamentals of react. Components, hooks, props, and states are some of the tools I've utilized. Furthermore, it is obtaining data from an API server.", tags: ["React Js", "React Hooks", "API", "Git", "Game"], link: "https://jeffreylustica.github.io/Quizzical-App/"},
    {id: 4, source: `${images.mathWizardRpg}`, secondaryTitle: "Vanilla JS App", mainTitle: "Math Wizard RPG", desc: "A JavaScript-based role-playing game application. You need to be proficient in certain fundamental math operations to win this game. To generate characters, I used JavaScript classes.", tags: ["JavaScript", "Javascript Classes", "CSS", "RPG"], link: "https://jeffreylustica.github.io/Math-Wizard-RPG-app/"},
    {id: 5, source: `${images.colorSchemeGenerator}`, secondaryTitle: "Vanilla JS App", mainTitle: "Color-Scheme Generator", desc: "A web application that made use of an API server. Choosing the preferred color and type of scheme, creates a palette of colors. Built with standard CSS and JavaScript.", tags: ["JavaScript", "API", "CSS"], link: "https://jeffreylustica.github.io/Color-Scheme-Generator/"},
    {id: 6, source: `${images.passwordGenerator}`, secondaryTitle: "Vanilla JS App", mainTitle: "Password generator", desc: "A web application that generates random passwords based on the selected choices. created with vanilla JavaScript. String and array methods have been used.", tags: ["JavaScript", "Array Methods", "String Methods", "CSS"], link: "https://jeffreylustica.github.io/Password-Generator/"}
    
]

export default data