const images = {
    picSome: require('./pic-some.png'),
    portfolio: require('./portfolio.png'),
    quizzicalApp: require('./quizzical-app.png'),
    mathWizardRpg: require('./math-wizard-rpg.png'),
    colorSchemeGenerator: require('./color-scheme-generator.png'),
    passwordGenerator: require('./password-generator.png'),
    arrow: require('./arrow.svg'),
    logo: require('./logo.svg')
}

export default images;